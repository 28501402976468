<template>
  <div>
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      @save-button="saveButton()"
      @delete-button="deleteButton"
    ></table-component>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import measure from "@/model/measure.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Дата добавления" },
          { id: 3, name: "Дата изменения" },
          { id: 4, name: "Название" },
          { id: 5, name: "Описание" },
          { id: 6, name: "Коэффициент" },
          { id: 7, name: "Род. ед. измерения" },
          { id: 8, name: "Удален" },
          { id: 9, name: "Точность" },
          { id: 10, name: "Шаг" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      MEASURE: "Measure/STATE",
    }),
  },
  async created() {
    await this.checkPower();
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      measureAll: "Measure/GET_ALL_MEASURE",
      deleteMeasure: "Measure/DELETE_MEASURE",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async setBody() {
      await this.measureAll();
      let model = new measure();
      this.items.body = model.setBody(this.MEASURE.allMeasure);
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteMeasure(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody();
      this.loading = false;
    },
  },
};
</script>
